import { useEffect, useRef } from "react";

interface PendoOptions {
  visitorId: string;
  visitorMetadata?: object;
  accountId?: string;
}

const usePendo = () => {
  const visitorId = useRef<string>("VISITOR-UNIQUE-ID");
  const visitorMetadata = useRef<object>({});
  const accountId = useRef<string | null>(null);

  useEffect(() => {
    let apiKey = "42553ce2-578a-41e8-5f0b-6b0360233992";
    // Add Pendo script to the DOM
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
    document.head.appendChild(script);
    script.onload = () => {
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: visitorId.current,
          },
          account: {
            id: accountId.current || "",
          },
        });
      }
    };
    return () => {
      // Clean up if necessary
      script.remove();
    };
  }, []);

  useEffect(() => {
    if (window.pendo === undefined) return;

    console.log("Pendo options:", visitorId.current, accountId.current, visitorMetadata.current);
    window.pendo?.updateOptions({
      visitor: {
        id: visitorId.current,
        ...visitorMetadata.current,
      },
      account: {
        id: accountId.current || "",
      },
    });
  }, [window.pendo, visitorId, visitorMetadata, accountId]);

  const updateOptions = (options: PendoOptions) => {
    visitorId.current = options.visitorId;
    visitorMetadata.current = options.visitorMetadata || {};
    accountId.current = options.accountId || null;
  };

  return {
    updatePendoOptions: updateOptions,
  };
};

export default usePendo;
