import {
  BiomarkersResponse,
  UnprocessableVideoResponse,
  IntelliProveAPIError,
  IntelliProveSDKError,
  LiveError,
  LiveErrorAction,
  LiveQualityCheck,
  LiveResults,
  QualityResponse,
  FpsTooLowMediaError,
  ResolutionMediaError,
  NoCameraMediaError,
  CameraStatus,
  IntelliProveMediaError,
} from "intelliprove-streaming-sdk";
import { useCallback, useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { MeasurementState } from "@/services/measurement_state";
import { useAppDispatch, useAppSelector } from "./store.hook";
import { resetGlobalError } from "@/store/reducers/globalError.reducer";
import { iAppModal } from "@/components/Modal/Modal";
import { debugLog, mapErrorCode, mapQualityErrorCode, openInNewTab } from "@/utils/helper";
import { WindowContext } from "@/providers/WindowContext.provider";
import { Subscription } from "rxjs";
import { messageService } from "@/utils/messaging.service";
import { monitor } from "@/utils/monitoring.service";
import { AttachOptions } from "intelliprove-streaming-sdk";
import { getBrowserCameraHintUrl } from "@/utils/browsers";
import { IntelliProveService } from "@/services/intelliprove.service";
import { useSDK } from "@/providers/Sdk.provider";

export const useMeasurement = () => {
  const sdkRef: IntelliProveService = useSDK();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { windowIsActive } = useContext(WindowContext);
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);

  const [videoLoading, setVideoLoading] = useState(true);
  const [qualityResponse, setQualityResponse] = useState<QualityResponse | null>(null);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [status, setStatus] = useState<MeasurementState>(MeasurementState.Idle);

  const [result, setResult] = useState<BiomarkersResponse>();
  const [error, setError] = useState<iAppModal>();
  const [finished, setFinished] = useState<boolean>(false);
  const [liveResult, setLiveResult] = useState<LiveQualityCheck | LiveResults | LiveError>();
  const [liveResultError, setLiveResultError] = useState<LiveError | LiveQualityCheck | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([]);
  const [flippedCamera, setFlippedCamera] = useState(true);
  const [activateCameraIndex, setActiveCameraIndex] = useState(0);

  let subscriptions: Subscription[] = [];
  let liveErrorTimeout: NodeJS.Timer | undefined = undefined;

  // Timeouts
  const __resetState = () => {
    setTimeLeft(null);
    setError(undefined);
    dispatch(resetGlobalError());
    setResult(undefined);
    setStatus(MeasurementState.Idle);
    setLiveResult(undefined);
    setLoading(false);
    setLiveResultError(undefined);

    sdkRef?.reset();
  };

  const showErrorModal = (title_key: string, desc_key: string, reason?: string, onTryAgain?: () => void, icon?: string) => {
    monitor.trackErrorModal(reason);

    let secondaryAction =
      setting.pluginSettings?.embedded ?? true
        ? () => {
            messageService.userFinished();
            messageService.dismiss();
            monitor.trackContinue();
          }
        : undefined;

    setError({
      isOpen: true,
      title: title_key,
      text: desc_key,
      icon: icon,
      secondaryAction: secondaryAction
        ? {
            text: t("close_current_measurement"),
            onPress: secondaryAction,
          }
        : undefined,
      action: {
        text: t("try_again"),
        onPress: () => {
          if (onTryAgain) {
            onTryAgain();
          } else {
            __resetState();
            setFinished(false);
          }
          monitor.trackTryAgain();
        },
      },
    });
  };

  const __getActiveVideoStream = (): MediaStream | null => {
    const vid: HTMLVideoElement | null = document.getElementById("video") as HTMLVideoElement | null;
    if (!vid) return null;

    const stream = vid.srcObject as MediaStream;
    if (!stream) return null;
    return stream;
  };

  const __getActiveVideoTrack = (): MediaStreamTrack | null => {
    const stream = __getActiveVideoStream();
    if (stream === null) return null;

    const tracks = stream.getVideoTracks();
    if (tracks.length === 0) return null;

    return tracks[0];
  };

  const __checkFacingMode = () => {
    const track = __getActiveVideoTrack();
    if (track === null) return;

    const settings = track.getSettings();
    if (settings.facingMode === "user") {
      setFlippedCamera(true);
    } else if (settings.facingMode === "environment") {
      setFlippedCamera(false);
    }
  };

  const toggleTorch = useCallback((light: boolean): boolean => {
    const track = __getActiveVideoTrack();
    if (track === null) return false;

    try {
      const cap = track.getCapabilities();
      if ("torch" in cap && cap.torch === true) {
        (<any>track).applyConstraints({ advanced: [{ torch: light }] }); // (<any> ) = workaround for TS to accept this code
        return true;
      }
      return false;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }, []);

  const changeCamera = useCallback(
    async (cameraId?: string) => {
      if (!sdkRef) {
        console.error(`SDK is not initialized yet!`);
        return;
      }

      if (sdkRef.state !== MeasurementState.Idle) {
        console.error("Tried to change cameras while measurement state is not Idle");
        return;
      }

      const __setPreferredCamera = (cameraId: string) => {
        localStorage.setItem("preferred-camera", cameraId);
      };

      setVideoLoading(true);
      if (cameraId) {
        sdkRef.instance
          ?.changeCamera(cameraId)
          .then(() => {
            __checkFacingMode();
            __setPreferredCamera(cameraId);
            setActiveCameraIndex(sdkRef!.instance!.selectedCameraIdx);
          })
          .catch((e) => {
            console.error(e);
            messageService.error("Error while trying to change cameras!", -1, true);
            handleIntelliProveSdkError(e);
          })
          .finally(() => setVideoLoading(false));
      } else {
        sdkRef.instance
          ?.nextCamera()
          .then((cameraId?: string) => {
            __checkFacingMode();
            if (cameraId) __setPreferredCamera(cameraId);
            setActiveCameraIndex(sdkRef!.instance!.selectedCameraIdx);
          })
          .catch((e) => {
            console.error(e);
            messageService.error("Error while trying to change cameras!", -1, true);
            handleIntelliProveSdkError(e);
          })
          .finally(() => setVideoLoading(false));
      }
    },
    [status, sdkRef],
  );

  const startMeasurement = useCallback(async () => {
    if (!sdkRef) {
      throw new ReferenceError("SDK Ref is not set or SDK not initialized yet!");
    }

    if ([MeasurementState.QualityCheck, MeasurementState.Measurement].includes(sdkRef.state)) {
      return await stopMeasurement("StopButton");
    }

    if (!sdkRef?.canStartMeasurement()) {
      return;
    }

    try {
      const streamStatus = await sdkRef.startMeasurement(setting.metadata);
    } catch (e) {
      messageService.error("Error while starting the measurement", -1, true);
      handleIntelliProveSdkError(e);
    }
    return;
  }, [status]);

  const getStatusMessage = () => {
    switch (status) {
      case MeasurementState.QualityCheck:
        var promptKey = "qc_is_busy";
        if (liveResultError) {
          if (liveResultError instanceof LiveQualityCheck && liveResultError.error_code !== 0) {
            promptKey = mapQualityErrorCode(liveResultError.error_code).live;
          } else {
            promptKey = mapErrorCode(liveResultError.error_code).live;
          }
        } else if (qualityResponse && qualityResponse.error_code !== 0) {
          promptKey = mapQualityErrorCode(qualityResponse.error_code).title;
        }

        return t(promptKey);

      case MeasurementState.Measurement:
        var promptKey = "motivation_doing_good";

        if (liveResultError) {
          if (liveResultError instanceof LiveQualityCheck && liveResultError.error_code !== 0) {
            promptKey = mapQualityErrorCode(liveResultError.error_code).live;
          } else {
            promptKey = mapErrorCode(liveResultError.error_code).live;
          }
        } else if (qualityResponse && qualityResponse.error_code !== 0) {
          promptKey = mapQualityErrorCode(qualityResponse.error_code).title;
        }

        return t(promptKey);
      default:
        // Hack to make the element have weight
        // @todo: Please give measurement-status-wrapper some dimension or do some magic?
        return "\u00A0";
    }
  };

  const stopMeasurement = async (cause: string, reset: boolean = true) => {
    await sdkRef?.abort();
    if (reset) {
      __resetState();
    }
    monitor.trackRecordingStopped(cause);
  };

  const handleLiveResult = async (value: LiveResults) => {
    if (!sdkRef?.instance?.status) {
      return;
    }

    if (setting.pluginSettings?.feature_flags?.live_results ?? false) {
      setLiveResult(value);
    }

    if (!("final" in value) || value["final"] !== true) {
      // Results are not final
      monitor.trackLiveResults(false);
      return;
    }
    monitor.trackLiveResults(true);

    let uuid = sdkRef?.instance?.status.uuid;

    // disable face landmark
    sdkRef?.instance?.faceLandmark?.setEnabled(false);

    if (setting.pluginSettings?.skip_results) {
      try {
        const result = await sdkRef!.finishAndNotifyResult(uuid);
        setFinished(true);

        if (result instanceof UnprocessableVideoResponse) {
          const errorKeys = mapErrorCode(result.errorCode);
          showErrorModal(t(errorKeys.title), t(errorKeys.text), `Error on final results [${result.errorType} - Code: ${result.errorCode}]`);
        } else {
          monitor.trackFinalResults(result.heart_rate_variability !== null);
          messageService.dismiss();
        }
      } catch (e) {
        setFinished(true);
        messageService.error("Error occurred when trying to finish measurement", -1, true);
        handleIntelliProveSdkError(e);
      }
      return;
    }

    try {
      sdkRef?.finish().then(async (result) => {
        debugLog("Got result!", result);
        setFinished(true);
        setTimeout(() => {
          navigate(`/measurement/result/${uuid}`, {
            state: { finished, result, from: location.pathname },
          });
          __resetState();
        });

        if (result instanceof BiomarkersResponse) {
          monitor.trackFinalResults(result.heart_rate_variability !== null);
        }
      });
    } catch (e) {
      messageService.error("Error occurred when trying to finish measurement", -1, true);
      handleIntelliProveSdkError(e);
    }
  };

  const handleLiveErrorOrQualityCheck = async (value: LiveError | LiveQualityCheck) => {
    if (!sdkRef?.instance?.status) {
      return;
    }

    if (value.action === LiveErrorAction.STOP) {
      if (sdkRef?.$state.value !== MeasurementState.Abort) {
        debugLog(`Recording stopped due to STOP action`, JSON.stringify(value));
        let eventType = value instanceof LiveError ? "LiveError" : "LiveQualityCheck";
        stopMeasurement(`StopEvent_${eventType}`, false);

        var errorKeys;
        switch (eventType) {
          case "LiveError":
            errorKeys = mapErrorCode(value.error_code);
            break;

          default:
            // LiveQualityCheck
            errorKeys = mapQualityErrorCode(value.error_code);
            break;
        }

        messageService.error(value.info, value.error_code, false);
        showErrorModal(
          t(errorKeys.title),
          t(errorKeys.text),
          `STOP event received during stream [${errorKeys.title} - Code ${value.error_code}]`,
        );
      }
    } else if (LiveErrorAction.RESET === value.action) {
      clearTimeout(liveErrorTimeout);
      liveErrorTimeout = undefined;
      debugLog(`Recording reset due to RESET action`, JSON.stringify(value));

      setLiveResultError(value);

      liveErrorTimeout = setTimeout(() => {
        setLiveResultError(undefined);
        liveErrorTimeout = undefined;
        debugLog(`Recording reset due to RESET action`, "clearing timeout!");
      }, 2000);
    }

    // Monitoring
    switch (value.action) {
      case LiveErrorAction.RESET:
      case LiveErrorAction.STOP:
        const isStop = value.action === LiveErrorAction.STOP;
        if (value instanceof LiveError) {
          monitor.trackStreamingError(value.error_code, value.type, isStop);
        } else {
          // LiveQualityCheck
          monitor.trackQualityCheck(value.error_code, false, isStop);
        }
        break;
      default: // CONTINUE
        break;
    }
  };

  const onFeedbackReceived = async (value: LiveQualityCheck | LiveResults | LiveError | null) => {
    if (!value) return;
    if (!sdkRef?.instance?.status) {
      return;
    }

    if (value instanceof LiveResults) {
      await handleLiveResult(value);
    } else if (value instanceof IntelliProveSDKError) {
      return;
    } else {
      await handleLiveErrorOrQualityCheck(value);
    }
  };

  const registerCallbacks = async () => {
    if (!sdkRef?.instance) {
      throw new Error(`IntelliProveSDK is not initialized!`);
    }

    if (subscriptions && subscriptions.length !== 0) {
      subscriptions.map((d) => d.unsubscribe());
    }

    if (sdkRef.instance?.debugMode && process.env.NODE_ENV !== "production") {
      sdkRef.instance?.toggleDebugMode();
    }

    sdkRef.onInitialQualityCheckFailed = (response) => {
      const errorKeys = mapQualityErrorCode(response.error_code);
      showErrorModal(
        t(errorKeys.title),
        t(errorKeys.text),
        `Initial quality check failed [${errorKeys.title} - Code ${response.error_code}]`,
      );
    };

    sdkRef.onRecordingStopped = () => {
      // monitor.trackRecordingStopped();
      messageService.recordingStopped();
    };

    sdkRef.onTimeLeft = setTimeLeft;

    subscriptions = [
      sdkRef.$qualityResponse.subscribe(setQualityResponse),
      sdkRef.$liveFeedback.subscribe(onFeedbackReceived),
      sdkRef.$state.subscribe(setStatus),
    ];
  };

  const handleIntelliProveSdkError = (e: any, onTryAgain?: () => void) => {
    var title = t("error_oops");
    var text = t("error_oops_description");

    __resetState();

    console.error(`SDK Error: ${e}`);
    monitor.trackError(e);

    showErrorModal(title, text, `SDK Error was raised [${e}]`, onTryAgain);
  };

  const checkMaxMeasurementsReached = (e: any) => {
    if (!(e instanceof IntelliProveAPIError)) return false;

    let api_error = e as IntelliProveAPIError;
    return api_error.status_code == 406;
  };

  const showMaxMeasurementsReachedModal = () => {
    let secondaryAction = setting.pluginSettings?.embedded
      ? () => {
          messageService.userFinished();
          messageService.dismiss();
          monitor.trackContinue();
        }
      : undefined;

    setError({
      isOpen: true,
      title: t("thank_you_title"),
      text: t("all_checks_used"),
      secondaryAction: secondaryAction
        ? {
            text: t("close_current_measurement"),
            onPress: secondaryAction,
          }
        : undefined,
    });
  };

  const showCameraModal = (cameraStatus: CameraStatus) => {
    // CameraStatus.NoCamera
    let icon: string = "no_camera";
    let dismissable: boolean = true;

    let errorTitle = t("error_camera_not_found");
    let errorDesc = t(`error_camera_not_found${isMobile ? "_mobile" : ""}_desc`);
    let actionBtnText = t("error_camera_reconnect");

    let secondaryBtnText = t("close_current_measurement");
    let secondaryFn =
      setting.pluginSettings?.embedded ?? true
        ? () => {
            messageService.userFinished();
            messageService.dismiss();
            monitor.trackContinue();
          }
        : undefined;

    let dismissFn = () => {
      setError(undefined);
    };

    if (cameraStatus === CameraStatus.Denied || cameraStatus === CameraStatus.Prompt) {
      // Prompt -> temp deny, Denied -> full block
      icon = "denied_camera";
      dismissable = false;

      errorTitle = t("error_camera_denied");
      errorDesc = t(`error_camera_denied${isMobile ? "_mobile" : ""}_desc`);
      actionBtnText = t("claim_granted_camera_permission");

      secondaryBtnText = t("error_camera_need_help");
      secondaryFn = () => {
        openInNewTab(getBrowserCameraHintUrl());
      };
    }

    monitor.trackErrorModal(errorTitle);

    setError({
      isOpen: true,
      title: errorTitle,
      text: errorDesc,
      onDismiss: dismissFn,
      dismissable: dismissable,
      icon: icon,
      action: {
        text: actionBtnText,
        onPress: () => {
          setError({});
          setTimeout(() => {
            initialize();
          }, 500);
        },
      },
      secondaryAction: secondaryFn
        ? {
            text: secondaryBtnText,
            onPress: secondaryFn,
          }
        : undefined,
    });
  };

  const initialize = async () => {
    if (sdkRef == null) return;

    try {
      sdkRef.instance?.faceLandmark?.setEnabled(true);
      const attachOptions: AttachOptions = {
        enableFaceLandmark: false,
        flipFaceLandmarkHorizontal: true,
      };

      const pref = localStorage.getItem("preferred-camera");
      if (pref) {
        attachOptions["cameraId"] = pref.toString();
      }

      await sdkRef.instance?.detach();
      await sdkRef.instance?.attach("video", attachOptions);
      setActiveCameraIndex(sdkRef!.instance!.selectedCameraIdx);
      setVideoLoading(false);

      // Reset state to initial
      __resetState();

      // fetch available cameras
      navigator.mediaDevices.enumerateDevices().then((items) => {
        setAvailableCameras(items.filter((i) => i.kind === "videoinput"));
        __checkFacingMode();
      });

      await registerCallbacks();
    } catch (e: any) {
      if (e instanceof IntelliProveMediaError) {
        switch (true) {
          case e instanceof NoCameraMediaError:
            const camStatus = await sdkRef.instance?.getCameraStatus();
            if (camStatus && [CameraStatus.Prompt, CameraStatus.Denied, CameraStatus.NoCamera].includes(camStatus)) {
              showCameraModal(camStatus);
            } else {
              // We fucked up -> CameraStatus == Available or undefined
              let k = isMobile ? "error_camera_not_found_mobile" : "error_camera_not_found";
              let errorTitle = t("error_camera_not_found");
              let errorDesc = t(`${k}_desc`);
              showErrorModal(errorTitle, errorDesc, `Got 'NoCameraMediaError' but camera permission is '${camStatus?.toString()}'`, () => {
                initialize();
              });
            }
            break;
          case e instanceof FpsTooLowMediaError:
            showErrorModal(t("error_insufficient_fps"), t("error_insufficient_fps_description"), e.message, () => {
              initialize();
            });
            break;
          case e instanceof ResolutionMediaError:
            showErrorModal(t("error_low_resolution"), t("error_low_resolution_description"), `Camera resolution too low`, () => {
              initialize();
            });
            break;
          default:
            // Unknown media error
            showCameraModal(CameraStatus.NoCamera);
            monitor.trackError(e);

            messageService.error(`Error while initialising the camera: ${e}`, -1, true);
        }
      } else if (checkMaxMeasurementsReached(e)) {
        // Max number of measurements where reached
        monitor.trackErrorModal("Maximum measurements count reached.");
        showMaxMeasurementsReachedModal();
      } else {
        // Another error, not concerning the SDK media service
        console.error(e);
        messageService.error("Something went wrong during the initialisation of the page.", -1, true);
        handleIntelliProveSdkError(e, () => {
          initialize();
        });
      }
    } finally {
      let actualResolution = sdkRef.instance?.media?.actualResolution();
      let requestedResolution = sdkRef.instance?.media?.resolution;
      let requestedFps = sdkRef.instance?.media?.fps;
      let maxSupportedfps = sdkRef.instance?.media?.maxSupportedFps();
      monitor.trackCameraInfo(requestedResolution, actualResolution, requestedFps, maxSupportedfps);
    }
  };

  useEffect(() => {
    if (!sdkRef) return;

    if (liveResultError) {
      sdkRef?.instance?.faceLandmark?.changeConfig({
        dotColor: setting.pluginSettings?.theming.functional_primary_500,
      });
      return;
    }

    if (qualityResponse) {
      if (qualityResponse.error_code !== 0) {
        sdkRef?.instance?.faceLandmark?.changeConfig({
          dotColor: setting.pluginSettings?.theming.functional_primary_500,
        });
      } else {
        sdkRef?.instance?.faceLandmark?.changeConfig({
          dotColor: setting.pluginSettings?.theming.functional_tertiary_500,
        });
      }
      return;
    }

    sdkRef?.instance?.faceLandmark?.changeConfig({
      dotColor: setting.pluginSettings?.theming.brand_primary,
    });
  }, [qualityResponse, liveResultError, sdkRef?.instance?.faceLandmark]);

  useEffect(() => {
    if (!sdkRef) return;

    if (!windowIsActive && sdkRef.state === MeasurementState.QualityCheck) {
      sdkRef?.stopQualityCheckLoop(true);
      __resetState();
      debugLog("Quality check aborted due to window is not visible");
    }
  }, [windowIsActive]);

  // Max time to wait for final results
  useEffect(() => {
    if (timeLeft === null || timeLeft > 0) return;
    const timer = setTimeout(() => {
      console.log("timer!");
      console.log("state", status);
      console.log("timer left", timeLeft);
      if (!finished) {
        showErrorModal(t("error_oops"), t("error_oops_description"), "Waited too long for final results");
      }
    }, 10_000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (setting.actionToken) {
      initialize();
    }

    // Clean up page resources
    return () => {
      /**
       * Possible bug on detach() when Measurement is still in progress
       * the immediatelly calling detach(), dataHandler will still be called
       * possibly for last time, but it was Throwing error due to this.status == null
       * intelliprove-streaming-sdk => src/sdk.ts:143
       */
      if (sdkRef?.state === MeasurementState.Measurement) {
        setTimeout(() => {
          sdkRef?.instance
            ?.detach()
            .then(() => {
              debugLog(`Intelliprove sdk: detached`);
            })
            .catch((e) => {
              monitor.trackError(e);
              debugLog(`error while trying to detach Intelliprove sdk`, e);
            });
        }, 2000);
      } else {
        sdkRef?.instance
          ?.detach()
          .then(() => {
            debugLog(`Intelliprove sdk: detached`);
          })
          .catch((e) => {
            monitor.trackError(e);
            debugLog(`error while trying to detach Intelliprove sdk`, e);
          });
      }

      subscriptions.map((d) => d.unsubscribe());
      subscriptions = [];
    };
  }, [setting, location.pathname]);

  return {
    videoLoading,
    timeLeft,
    qualityResponse,
    startMeasurement,
    changeCamera,
    toggleTorch,
    getStatusMessage,
    status,
    error,
    result,
    finished,
    stopMeasurement,
    liveResult,
    loading,
    liveResultError,
    availableCameras,
    flippedCamera,
    activateCameraIndex,
  };
};
